import React from 'react'
import FullPageBlock from '../full-page-block'
import weWorkImage from '../../images/wework.png'
import locatorImage from '../../images/locator.png'

const WhereWeWork = () => (
<div className="wework-container" style={{backgroundImage:'url(' + {weWorkImage }+')', bgColor: 'white'}}>
  <div className="container-fluid">
      <div className="place-desc row justify-content-center">
        <div className="wework-text col-md-10">
        Sunshine, beaches, cricket, and home to<br/> some of the biggest software companies.
        </div>
        <div className="wework-text-line col-md-5 ">
          <hr className="wework-line col-md-10 "/>
        </div>
      </div>
      <div className="address-block d-flex flex-row-reverse">
        <div className="wework-desc d-flex flex-column justify-content-center">
          <div className="wework-address-title">
            Where We Work
          </div>
          <div className="address d-flex flex-column justify-content-center">
            <div className="d-flex justify-content-center"><img alt="locator" src={locatorImage}/></div>
            <div className="d-flex justify-content-center">
            RMZ Millenia Business Park,<br/>
            Phase 2, 11, MGR Main Rd,<br/>
            Perungudi, Chennai.
            </div>
          </div>

        </div>
      </div>
    </div>

</div>
)

export default WhereWeWork
