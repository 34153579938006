import React from 'react'
import FullPageBlock from '../full-page-block'
import ContactUsGallery from './gallery'
import ScrollAnimation from 'react-animate-on-scroll';

import "../animations.css"

const greatPlaceList = [
  "prioritise",
  "focus",
  "dodge distractions",
  "zone out",
  "respect personal space",
  "find your calm",
  "play, have fun",
  "on top of it, deliver results"
]

const renderBullet = (pointItem, index) => (
  <ScrollAnimation animateIn="subtleFadeInUp" delay={index*200} offset={0.1*150} duration="0.5">
    <li>{pointItem}</li>
  </ScrollAnimation>
);

const greatPlace = () => (
  <div style={{  "background" : "#F4EAEA"}}>
    <div className="container-fluid">
      <div className="">
        <div className="row d-flex align-items-end justify-content-end">
          <div className="top-content col-md-9">
            A great work place is not unlimited supply of espresso,<br/>
            sumptuously furnished offices,<br/>
            fancy lunches, and larger than life parties.
          </div>
        </div>
        <div className="row d-flex align-items-end justify-content-end">
          <div className="left-list col-md-3 d-flex flex-md-column">
            <ScrollAnimation animateIn="subtleFadeInUp" duration="0.5">
              <p className="list-head d-flex flex-md-column">It is more than that:</p>
              <p className="list-desc">For us, it is a healthy, and <br/>
              dynamic environment that listens to <a target='_blank' class='text-links' href='https://www.culturemonkey.io/employee-engagement/employee-feedback-in-the-workplace-2022/'> employee feedback</a> and lets you,</p>
            </ScrollAnimation>
            <ul>
              {greatPlaceList.map((renderBullet))}
            </ul>
          </div>

          <div className="left-list col-md-6 d-flex flex-md-column align-items-end">
            <ContactUsGallery />
          </div>
        </div>
      </div>
    </div>

  </div>
)

export default greatPlace
