import React from 'react'
import Layout from '../components/layout'
import WhereWeWork from '../components/contact-us/wherewework'
import WorkWithUs from '../components/contact-us/workwithus'
import GreatPlace from '../components/contact-us/place'
import HQMap from '../components/contact-us/map'
import DropIn from '../components/contact-us/dropin'
import '../components/contact-us/contact-us.css'

const ContactUs = () => (
  <Layout>
    <WhereWeWork />
    <GreatPlace />
    <DropIn />
    {/* <HQMap isMarkerShown/> */}
    <WorkWithUs />
  </Layout>
)

export default ContactUs
