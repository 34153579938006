import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';

import "../animations.css"

const DropIn = () => (
	<div className="container-fluid">
		<div className="row dropin-container">
			<div className="dropin-bg"></div>
			<div className="col-md-6 dropin-left">
				<ScrollAnimation animateIn="subtleFadeInDown">
					The need,<br/>
					the road map,<br/>
					and the destination.
				</ScrollAnimation>
			</div>

			<div className="col-md-6 dropin-right d-flex align-items-end justify-content-end">
				<ScrollAnimation animateIn="subtleFadeInDown">
					Drop in, come say hello!
				</ScrollAnimation>
			</div>
		</div>
	</div>
)

export default DropIn
