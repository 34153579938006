import React from 'react'
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import slider1 from '../../images/contact-us-slider-1.jpg'
import slider2 from '../../images/contact-us-slider-2.jpg'

const ContactUsGallery = () => (
  
  <div className="gallery-container">
    <Slider {...{dots:true,infinite:true,speed:500,slidesToShow:1,
            slidesToScroll:1,arrows:false,autoplay:true,autoplaySpeed:4000}}>
        <div className="contact-us-image-container">
          <img src={slider1} />
        </div>
        <div className="contact-us-image-container">
          <img src={slider2} />
        </div>
    </Slider>
  </div>

)

export default ContactUsGallery
