import React from 'react'
import { withGoogleMap, withScriptjs,  GoogleMap } from "react-google-maps"
import { compose, withProps } from "recompose"
/* eslint-disable no-undef */

const { MarkerWithLabel } = require("react-google-maps/lib/components/addons/MarkerWithLabel");

const HQMap = compose(
  withProps({
      googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyCQGgBo-nsFq_CHXRi6WrHXDuW66RnGhV0&v=3.exp&libraries=geometry,drawing,places",
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: `400px` }} />,
      mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
  )((props) =>
  <div className="map-container" >
    <GoogleMap
      defaultZoom={16}
      defaultCenter={{ lat: 12.974147, lng: 80.242391 }}
    >
      <MarkerWithLabel
        position={{ lat: 12.974147, lng: 80.242391 }}
        labelAnchor={new google.maps.Point(-11, 25)}
        labelStyle={{color:"red", fontSize: "13px"}}
      >
        <div>effy HQ</div>
      </MarkerWithLabel>

    </GoogleMap>
  </div>

)

export default HQMap
