import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';

import "../animations.css"
import "animate.css/animate.min.css";

const WorkWithUs = () => (
  <div className="work-with-us d-flex flex-column">
    <div className="work-with-us-title">
      <ScrollAnimation animateIn="subtleFadeInUp" duration="0.4">
        Want to work
      </ScrollAnimation>
    </div>

    <div className="container">
      <div className="work-with-us-body row">

        <div className="work-with-us-item col-sm-12 col-md-6 justify-content-center">
          <div className="row text-block justify-content-center">
            <ScrollAnimation animateIn="subtleBounceInUp" duration="0.3">
              <div className="text">with us</div>
            </ScrollAnimation>
          </div>

          <div className="row button-block justify-content-center">
            <ScrollAnimation animateIn="subtleFadeInUp" duration="0.3" delay="200">
              <a className="work-with-us-button" href="https://effytech.typeform.com/to/cQm7Um">Discuss Project</a>
            </ScrollAnimation>
          </div>
        </div>

        <div className="work-with-us-item right col-sm-12 col-md-6 justify-content-center">
          <ScrollAnimation animateIn="fadeIn" delay="700" className="left-separator"></ScrollAnimation>
          <div className="row text-block justify-content-center">
            <ScrollAnimation animateIn="subtleBounceInUp" duration="0.3" delay="400">
              <div className="text">for us</div>
            </ScrollAnimation>
          </div>

          <div className="row button-block justify-content-center">
            <ScrollAnimation animateIn="subtleFadeInUp" duration="0.3" delay="600">
              <a className="work-with-us-button" href="https://effytech.typeform.com/to/QBVavl">Apply for work</a>
            </ScrollAnimation>
          </div>
        </div>

      </div>
    </div>
  </div>
)

export default WorkWithUs
